import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, CircularProgress, IconButton, Typography } from '@mui/material';
import { medicalCheckupsService } from '../../../services/medicalCheckupsService';
import { LookupDto } from '../../../models/common/lookup.dto';
import TmPrinter from '../../../icons/TmPrinter';

interface Props {
    medicalCheckupId: number;
}
const PAGE_REFRESH_INTERVAL = 10000;

const DocumentMenu: React.FC<Props> = ({ medicalCheckupId }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [documents, setDocuments] = useState<LookupDto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchDocuments = async () => {
        try {
            const response = await medicalCheckupsService.getDocuments(medicalCheckupId);
            setDocuments(response.data.data);
        } catch (error) {
            console.error('Error fetching documents:', error);
        } finally {
        }
    };

    useEffect(() => {
        fetchDocuments();
        const interval = setInterval(fetchDocuments, PAGE_REFRESH_INTERVAL);

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [medicalCheckupId]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownload = async (medDocument: LookupDto) => {
        setLoading(true);
        try {
            const response = await medicalCheckupsService.getDocumentDownloadLink(medicalCheckupId, medDocument.id);
            const url = response.data.data.link;
            window.location.href = url;
        } catch (error) {
            console.error('Error downloading document:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <IconButton
                className="list-item"
                onClick={handleClick}
                disabled={loading || documents.length === 0}
                sx={{
                    my: 2,
                    color: (theme) => (loading || documents.length === 0 ? theme.palette.action.disabled : 'inherit'),
                    opacity: loading || documents.length === 0 ? 0.5 : 1, // Reduce opacity when disabled
                }}
            >
                <TmPrinter />
                <Typography fontWeight="bold" sx={{ marginLeft: 1 }}>
                    Документы
                </Typography>
            </IconButton>

            {/* Loading Spinner */}
            {loading && <CircularProgress />}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {documents.map((document) => (
                    <MenuItem key={document.id} onClick={() => handleDownload(document)}>
                        {document.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default DocumentMenu;
