import { Layout } from "../../components/common/Layout";
import { MedicalCheckupDetails } from "../../components/medical-checkups/details/MedicalCheckupDetails";

export const MedicalCheckupDetailsPage = () => {

    const title = "Медосмотры";

    return (
        <Layout title={title}>
            <MedicalCheckupDetails/>
        </Layout>
    )
}